import React, {useState} from 'react'
import {Spin} from "antd";
//import {BASE_ICON_COLORS} from "../Forms/formConstants";
//import {TOOL_TIP_TEXT} from "../../util/staticTextConstants";
//import {BASE_PROJECT_COLORS as COLORS} from "../../util/colorConstants";
import {IdeaCard} from "./IdeaCard";
import {useSelector} from "react-redux";
import './Ideas.css'
import {listIdeas} from "../../features/firebase/firestoreIdeaSlice";

export const IdeaList = () => {

  const [filtered, setFiltered] = useState(true)
  // const list = useSelector(mockListIdea)
  const list = useSelector(listIdeas);

  // const flipHeartFilter = (boolean) => {
  //   setFiltered(boolean)
  // }

  // useEffect(() => {
  //   console.log('IDEALIST.JSX:::LIST:::', list)
  //
  // }, [list])
  
  return list?.length !== 0 ? (
     <div className={"Idea-list-container"}>
      <h3 className='title'>Lägg till dina egna idéer <a href="/form-idea"><button className="button">Här</button></a></h3>
       {/*<div className={"Idea-top-container"}>*/}
       {/*  <HeartFilterButton filtered={filtered} flipHeartFilter={flipHeartFilter}/>*/}
       {/*</div>*/}
      
      
       <div className={"Idea-grid-container"}>
       
         {filtered ?
            filterHearted(list).map((item) => (
               <IdeaCard key={item.documentId} item={item}/>
            ))
            : list ? (
               <React.Fragment>
                 {
                   list.map((item) => (
                      <IdeaCard key={item.documentId} item={item}/>
                   ))
                 }
               </React.Fragment>
            ) : null
         }
       </div>

     </div>
  ) : <Spin/>
}

// const HeartFilterButton = ({filtered, flipHeartFilter}) => {
//
//   return (
//      filtered ? (
//         <Tooltip
//            title={TOOL_TIP_TEXT.BTN_FILTER_HEARTED_CLEAR}
//            color={COLORS.DARK_GRAY}
//         >
//           <Button className={'Heart-filter-button'} style={{outline: 'none', border: 'none'}}
//                   onClick={() => flipHeartFilter(false)} shape="circle">
//             <HeartTwoTone twoToneColor={BASE_ICON_COLORS.heart_red}/>
//           </Button>
//         </Tooltip>
//      ) : (
//         <Tooltip
//            title={TOOL_TIP_TEXT.BTN_FILTER_HEARTED}
//            color={COLORS.DARK_GRAY}
//         >
//           <Button className={'Heart-filter-button'} style={{outline: 'none'}}
//                   onClick={() => flipHeartFilter(true)} shape="circle">
//             <HeartTwoTone twoToneColor={BASE_ICON_COLORS.unmarked}/>
//           </Button>
//         </Tooltip>
//      )
//   )
// }

function filterHearted(list) {
  
  return list?.filter(item =>(item).creation_date >= "1684635243061" && item.title.length < 50 && item.text.length<250
  );
 
}





